<template>
  <div>
    <Dialog @hide="$emit('close')" :draggable="false" v-model:visible="showDialogLocal" :style="{width: '40%'}" :header="vehicle ? vehicle.name : 'Nouveau véhicule'" :modal="true" class="p-fluid">
      <div class="flex flex-column" style="gap: 1.5rem;">
        <div class="flex flex-column" style="gap: 1.5rem;">
          <div>
            <div class="field">
              <label>Nom du véhicule</label>
              <div>
                <InputText v-model="v$.name.$model" type="text"/>
              </div>
            </div>
            <div v-if="v$.name.$silentErrors.length !== 0 && submitted" class="mb-2 mt-3">
                <span id="email-error" v-for="(error, index) of v$.name.$silentErrors" :key="index">
                  <InlineMessage>{{error.$message}}</InlineMessage>
                </span>
            </div>
          </div>
          <div>
            <div class="field">
              <label>Catégorie</label>
              <div class="flex justify-content-start">
                <Dropdown v-model="v$.category.$model" :options="vehicleCategories" class="w-6"></Dropdown>
              </div>
            </div>
            <div v-if="v$.category.$silentErrors.length !== 0 && submitted" class="mb-2 mt-3">
                <span id="category-error" v-for="(error, index) of v$.category.$silentErrors" :key="index">
                  <InlineMessage>{{error.$message}}</InlineMessage>
                </span>
            </div>
          </div>
          <div>
            <div class="field">
              <label>Chevaux fiscaux</label>
              <div class="flex justify-content-start">
                <Dropdown v-model="v$.horsepower.$model" :options="chevauxFiscaux" option-label="label" class="w-6"></Dropdown>
              </div>
            </div>
            <div v-if="v$.horsepower.$silentErrors.length !== 0 && submitted" class="mb-2 mt-3">
                <span id="horsepower-error" v-for="(error, index) of v$.horsepower.$silentErrors" :key="index">
                  <InlineMessage>{{error.$message}}</InlineMessage>
                </span>
            </div>
          </div>
          <div>
            <div class="field">
              <label>Plaque d'immatriculation *</label>
              <div>
                <InputText v-model="v$.immatriculation.$model" type="text"/>
              </div>
            </div>
            <div v-if="v$.immatriculation.$silentErrors.length !== 0 && submitted" class="mb-2 mt-3">
                <span id="immatriculation-error" v-for="(error, index) of v$.immatriculation.$silentErrors" :key="index">
                  <InlineMessage>{{error.$message}}</InlineMessage>
                </span>
            </div>
          </div>
          <div class="field">
            <label>Carte grise *</label>
            <div class="flex">
              <FileUpload v-if="hasAccess($route.name, true)" accept="image/png, image/jpeg, application/pdf" mode="basic" choose-label="Charger" auto custom-upload @uploader="(file) => { this.carteGrise = file }" class="p-button-rounded mr-3"/>
              <Button v-if="carteGrise" type="button" icon="pi pi-eye" class="p-button-success" @click="reviewCarteGrise(this.carteGrise)" style="margin-right: .5em"></Button>
            </div>
          </div>
        </div>
      </div>
      <template #footer v-if="hasAccess($route.name, true)">
        <Button label="Enregistrer le véhicule" icon="pi pi-check" class="p-button-text" @click="saveVehicle" />
      </template>
    </Dialog>
    <Dialog v-model:visible="dialogReview" position="right" :style="{width: '25%'}" dismissable-mask class="p-fluid">
      <iframe v-if="imgSrc.includes('application/pdf')" style="width: 100%; height: 50vh" :src="imgSrc + '#toolbar=0&navpanes=0&scrollbar=0'" />
      <img v-else style="width: 100%" :src="imgSrc" />
    </Dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {useVuelidate} from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import { hasAccess } from "@/utils/Misc";

export default {
  name: "DialogVehicle",
  emits: ["close", "reload"],
  props: ['dialog', 'vehicle'],
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      name: {
        required: helpers.withMessage('Nom requis', required),
      },
      category: {
        required: helpers.withMessage('Catégorie requise', required),
      },
      immatriculation: {
        required: helpers.withMessage('Immatriculation requise', required),
        format: helpers.withMessage('Veuillez renseigner un bon format d\'immatriculation', (value) => {
          const regex = new RegExp('^[A-Za-z]{1,2}-[0-9]{3}-[A-Za-z]{2}$')
          return regex.test(value)
        })
      },
      horsepower: {
        required: helpers.withMessage('Chevaux fiscaux requis', required),
      },
    }
  },
  data () {
    return {
      hasAccess: hasAccess,
      submitted: false,
      showDialogLocal: this.dialog,
      name: null,
      category: null,
      immatriculation: null,
      horsepower: null,
      carteGrise: null,
      dialogReview: false,
      imgSrc: null,
      vehicleCategories: [
          'Voiture thermique',
          'Voiture électrique',
          'Moto',
          'Cyclomoteur'
      ]
    }
  },
  watch: {
    dialog (val) {
      this.showDialogLocal = val
    },
    category () {
      if (this.vehicle?.category !== this.category) this.horsepower = null
    }
  },
  computed: {
    ...mapGetters({
      independent: 'independent/getIndependent',
    }),
    chevauxFiscaux () {
      switch (this.category) {
        case 'Voiture électrique':
        case 'Voiture thermique':
          return [{label: '3 CV et moins', value: "3"}, {label: '4 CV', value: "4"}, {label: '5 CV', value: "5"}, {label: '6 CV', value: "6"}, {label: '7 CV et plus', value: "7"}]
        case 'Moto':
          return [{label: '1 ou 2 CV', value: "2"}, {label: '3, 4 ou 5 CV', value: "5"}, {label: '6 CV et plus', value: "6"}]
        case 'Cyclomoteur':
          return [{label: 'Non applicable', value: "0"}]
      }
      return []
    }
  },
  created () {
    if (this.vehicle) {
      this.name = this.vehicle.name
      this.category = this.vehicle.category
      this.immatriculation = this.vehicle.immatriculation
      this.horsepower = this.chevauxFiscaux.find((cv) => cv.value === this.vehicle.horsepower)
      this.carteGrise = this.vehicle.carteGrise
    }
  },
  methods: {
    async reviewCarteGrise (file) {
      this.$store.state.misc.loading = true
      let transformToPdf = true
      if (typeof file === 'string') {
        const result = await this.$store.dispatch('file/downloadFile', {id: file})
        if (result.mimetype.includes('image')) transformToPdf = false
        file = new Blob([new Uint8Array(result.data.data)])
      } else {
        file = file.files[0]
      }
      const reader = new FileReader()
      reader.onload = () => {
        this.imgSrc = reader.result
        if (transformToPdf) {
          this.imgSrc = this.imgSrc.replace('application/octet-stream', 'application/pdf')
        }
        this.dialogReview = true
        this.$store.state.misc.loading = false
      }
      reader.readAsDataURL(file)
    },
    async saveVehicle () {

      if (this.v$.$invalid) {
        this.submitted = true
        return
      }

      let carteGrise = null

      if (this.carteGrise !== null && typeof this.carteGrise !== 'string') {
        const form = new FormData()
        form.append('filename', this.carteGrise.files[0].name)
        form.append('file', this.carteGrise.files[0], this.carteGrise.files[0].name)
        carteGrise = await this.$store.dispatch('file/uploadFile', form)
      } else {
        if (!this.carteGrise) {
          this.$toast.add({severity:'error', summary:'Erreur', detail:'Veuillez importer votre carte grise', life: 3000})
          return
        }
      }

      const vehicle = {
        name: this.name,
        category: this.category,
        immatriculation: this.immatriculation,
        horsepower: this.horsepower.value,
        independent: this.independent.id,
        'carteGrise': carteGrise
      }

      this.$store.state.misc.loading = true
      let result
      if (this.vehicle?.id) {
        vehicle['id'] = this.vehicle.id
        result = await this.$store.dispatch('vehicle/updateVehicle', vehicle)
      } else {
        result = await this.$store.dispatch('vehicle/createVehicle', vehicle)
      }

      this.$store.state.misc.loading = false
      this.$toast.add({severity:'success', summary:'Succès', detail:'Véhicule sauvegardé', life: 3000});
      this.$emit('reload')
      this.$emit('close', result)
    },
  }
}
</script>

<style>
.dialog-content-loading {
  background-color: transparent !important;
}
</style>

<style lang="scss" scoped>
::v-deep(.p-dialog) {
  box-shadow: none !important;
}
</style>

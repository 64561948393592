<template>
  <div>
    <Dialog v-model:visible="showDialogLocal" :draggable="false" :style="{width: '40%'}" header="IKM" :modal="true" class="p-fluid" @hide="$emit('close')">
      <div class="flex flex-column" style="gap: 1.5rem;">
        <div class="flex flex-column" style="gap: 1.5rem;">
          <div class="flex flex-column gap-4 xhl:flex-row">
            <div class="mb-0 p-0 flex align-items-center gap-3 xhl:w-6" v-if="!(ikm && ikm.ndf)">
              <p class="w-3 m-0 p-0 text-sm font-bold line-height-3 xhl:w-2">Vos trajets : </p>
              <div class="flex flex-row justify-content-end align-items-center w-9 gap-3 xhl:justify-content-start">
                <Dropdown :options="trajets" v-model="trajet" data-key="id" option-label="name" class="w-9"></Dropdown>
                <div>
                  <Button type="button" icon="pi pi-plus" class="p-button" size="small" @click="dialogTrajet = true"/>
                </div>
              </div>
            </div>
            <div class="mb-0 p-0 flex align-items-center gap-3 xhl:w-6">
              <p class="w-3 m-0 p-0 text-sm font-bold line-height-3 xhl:w-2">Véhicule : </p>
              <div class="flex flex-row justify-content-end align-items-center w-9 gap-3 xhl:justify-content-start">
                <Dropdown :disabled="ikm && ikm.ndf !== null" :options="vehicles" data-key="id" v-model="vehicle" option-label="name" class="w-9"></Dropdown>
                <div>
                  <Button v-if="!(ikm && ikm.ndf)" type="button" icon="pi pi-plus" class="p-button h-auto" size="small" @click="dialogVehicle = true"/>
                </div>
              </div>
            </div>
            <div v-if="me.role.name === constants.ROLES.ADMIN && vehicle?.horsepower" class="mb-0 p-0 flex align-items-center gap-3 xhl:w-6">
              <p class="w-3 m-0 p-0 text-sm font-bold line-height-3 xhl:w-2">Chevaux : </p>
              <div class="flex flex-row justify-content-end w-9 gap-3 xhl:justify-content-start">
                <InputNumber v-model="vehicle.horsepower" disabled suffix=" CH"/>
              </div>
            </div>
          </div>
          <div class="formgrid grid mt-5 align-items-center">
            <div class="field col flex">
              <label for="refacturable" class="mr-5">Refacturable client</label>
              <div>
                <Checkbox id="refacturable" :disabled="ikm?.ndf && ikm.ndf.status !== 'validate'" :binary="true" v-model="refacturable"/>
              </div>
            </div>
            <div class="field col" v-if="refacturable">
              <label for="mission">Mission</label>
              <Dropdown id="mission" :disabled="ikm?.ndf && ikm.ndf.status !== 'validate'" :option-label="(val) => val.clientContract.name" v-if="missions" v-model="mission" :options="missions">
              </Dropdown>
            </div>
          </div>
          <div class="field">
            <label>Date(s) des trajets</label>
            <Calendar :disabled="ikm && ikm.ndf !== null" :showIcon="true" :min-date="minDate" :max-date="maxDate" v-model="dates" selection-mode="multiple" :manual-input="false" date-format="dd/mm/yy"/>
          </div>
          <div class="field">
            <label>Lieu de départ</label>
            <Dropdown :disabled="ikm && ikm.ndf !== null" :loading="departLoading" @change="suggestions = []; getDistance()" v-model="lieuDepart" placeholder="Adresse de départ" :options="suggestions" @filter="(event) => { suggestions = []; getAutocomplete(event); departLoading = true}" option-label="properties.label" filter-placeholder="Recherche d'adresse" :empty-filter-message="searchResultDescription" empty-message="Veuillez effectuer une recherche" filter-match-mode="NO_FILTER" :filter="true" :showClear="false">
              <template #option="data">
                <span>{{ data.option.properties.label }}</span>
              </template>
              <template #value="slotProps">
                <span :style="!slotProps.value?.properties?.label ? 'visibility: hidden' : ''">
                {{ slotProps.value?.properties?.label ?? 'p-emptylabel' }}
              </span>
              </template>
            </Dropdown>
          </div>
          <div class="field">
            <label>Lieu d'arrivée</label>
            <Dropdown :disabled="ikm && ikm.ndf !== null" :loading="arriveeLoading" @change="suggestions = []; getDistance()" v-model="lieuArrivee" placeholder="Adresse d'arrivée" :options="suggestions" @filter="(event) => { suggestions = []; getAutocomplete(event); arriveeLoading = true}" option-label="properties.label" filter-placeholder="Recherche d'adresse" :empty-filter-message="searchResultDescription" empty-message="Veuillez effectuer une recherche" filter-match-mode="NO_FILTER" :filter="true" :showClear="false">
              <template #option="data">
                <span>{{ data.option.properties.label }}</span>
              </template>
              <template #value="slotProps">
                <span :style="!slotProps.value?.properties?.label ? 'visibility: hidden' : ''">
                {{ slotProps.value?.properties?.label ?? 'p-emptylabel' }}
              </span>
              </template>
            </Dropdown>
          </div>
          <div class="field">
            <label>Distance {{ showCalculatedDistance ? 'renseignée ' : ''}} du trajet</label>
            <InputNumber style="width: 65%" :disabled="ikm && ikm.ndf !== null" @input="updateDistance" v-model="distance" suffix=" km" :min="0"/>
          </div>
          <div v-if="showCalculatedDistance" class="field">
            <label>Distance calculée du trajet</label>
            <InputNumber style="width: 65%" :disabled="true" v-model="calculatedDistance" suffix=" km" :min="0"/>
          </div>
          <div class="field">
            <label>Nombre de trajets</label>
            <InputNumber style="width: 65%" :disabled="ikm && ikm.ndf !== null" @input="updateNombreTrajets" v-model="nombreTrajets" :min="0"/>
          </div>
        </div>
        <div class="col-12 mt-5">
          <div class="card widget-boxes p-0 grid grid-nogutter">
            <div class="col-12 md:col-6 lg:col-6 py-2 px-6 border-none md:border-right-1 border-solid">
              <div class="mb-3">
                <Avatar icon="pi pi-car" size="large" shape="circle" class="text-base font-bold"
                        :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}"></Avatar>
                <span class="text-xl ml-2">Distance totale</span>
              </div>
              <span class="block font-bold text-5xl mb-3">{{ distanceTotale }} km</span>
            </div>
            <div class="col-12 md:col-6 lg:col-6 py-2 px-6 border-none">
              <div class="mb-3">
                <Avatar icon="pi pi-money-bill" size="large" shape="circle" class="text-base font-bold"
                        :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}"></Avatar>
                <span class="text-xl ml-2">Prix</span>
              </div>
              <div class="flex align-items-center">
                <span class="block font-bold text-5xl mb-3">{{ prixIKMComputed.toFixed(2) }} €</span>
                <i v-if="vehicle && getDefaultBareme(vehicle) == 2" v-tooltip.right="'Un ajustement important aura lieu lors de la régularisation des IKM.'" class="text-xl ml-1 mb-3 pi pi-info-circle text-blue-500 cursor-pointer"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #footer v-if="!ikm?.ndf || ikm?.ndf.status === 'validate' && hasAccess($route.name, true)">
        <Button v-if="ikm" label="Supprimer l'IKM" icon="pi pi-trash" class="p-button-text text-red-400" @click="removeIkm"/>
<!--        <Button label="Enregistrer l'IKM" icon="pi pi-check" class="p-button-text" @click="saveIkm" />-->
        <Button v-if="!(ikm && ikm.ndf)" label="Affecter l'IKM" icon="pi pi-check" class="p-button-text" @click="saveAndAssignIkm" />
      </template>
    </Dialog>
    <DialogVehicle @reload="loadData" @close="close" v-if="dialogVehicle" :dialog="dialogVehicle"></DialogVehicle>
    <DialogTrajet @reload="loadData" @close="close" v-if="dialogTrajet" :dialog="dialogTrajet"></DialogTrajet>
    <Dialog v-if="me.role.name === constants.ROLES.ADMIN" v-model:visible="grayCardDialog" position="right"
            :style="{width: '25%'}" dismissable-mask class="p-fluid">
      <template #header>
        <div class="flex flex-row gap-2 align-items-center">
          <h3 class="pb-0 mb-0">Carte grise du véhicule</h3>
          <Button icon="pi pi-download" class="p-button-rounded" size="small" @click="downloadFile"/>
        </div>
      </template>
      <iframe v-if="grayCardDialogImgSrc?.includes('application/pdf')" style="width: 100%; height: 50vh" :src="grayCardDialogImgSrc + '#toolbar=0&navpanes=0&scrollbar=0'" />
      <img v-else style="width: 100%" :src="grayCardDialogImgSrc" />
      <div v-if="!grayCardDialogImgSrc" class="flex flex-row gap-2 text-xl">
        <i class="pi pi-exclamation-triangle mr-3 text-orange-400 text-4xl" />
        <p class="pb-0">La carte grise de ce véhicule n'est pas disponible</p>
      </div>
    </Dialog>
  </div>
</template>

<script>
import {getEuroFormat} from "@/utils/Money";
import {mapGetters, mapState} from "vuex";
import DialogTrajet from "@/components/NDF/DialogTrajet";
import DialogVehicle from "@/components/NDF/DialogVehicle";
import {getBase64FromDownloadedFile, hasAccess} from "@/utils/Misc";

export default {
  name: "DialogIkm",
  components: {DialogVehicle, DialogTrajet},
  emits: ["close", "reload"],
  props: ['dialog', 'ikm', 'showCalculatedDistance'],
  data () {
    return {
      hasAccess: hasAccess,
      showDialogLocal: this.dialog,
      dates: [],
      trajet: null,
      trajets: [],
      vehicle: null,
      vehicles: [],
      lieuDepart: null,
      lieuArrivee: null,
      nombreTrajets: 1,
      distance: 0,
      calculatedDistance: 0,
      prix: '',
      timeout: null,
      suggestions: [],
      departLoading: false,
      arriveeLoading: false,
      searchResultDescription: '',
      dialogVehicle: false,
      dialogTrajet: false,
      distanceTotale: 0,
      maxDate: null,
      minDate: null,
      refacturable: false,
      mission: null,
      missions: [],
      grayCardDialog: false,
      grayCardDialogImgSrc: null
    }
  },
  watch: {
    dialog (val) {
      this.showDialogLocal = val
    },
    trajet (val) {
      this.lieuDepart = val.jsonDepart
      this.lieuArrivee = val.jsonArrivee
      this.getDistance()
    },
    dates () {
      if (this.dates.length === 0) {
        this.maxDate = null
        this.minDate = null
      } else {
        this.dates.sort((a, b) => { return a - b })
        this.minDate = new Date(this.dates[0].getFullYear(), this.dates[0].getMonth(), 1)
        this.maxDate = new Date(this.dates[0].getFullYear(), this.dates[0].getMonth() + 1, 0)
      }
    }
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      settings: state => state.misc.settings,
      constants: state => state.constants,
    }),
    ...mapGetters({
      independent: 'independent/getIndependent',
      ikmBD: 'ikm/getIkm'
    }),
    prixIKMComputed () {
      if (!this.vehicle) return 0
      const resultBareme = this.getBareme(this.vehicle)
      return resultBareme.value * this.distanceTotale
    }
  },
  async created () {
    await this.loadData()
    if (this.ikm) {
      this.dates = this.ikm.dates
      this.vehicle = this.vehicles.find(v => v.id === (this.ikm.vehicle.id?? this.ikm.vehicle))
      this.lieuDepart = this.ikm.depart
      this.lieuArrivee = this.ikm.arrivee
      this.distance = this.ikm.distanceTrajet
      this.calculatedDistance = this.ikm.calculatedDistance
      this.nombreTrajets = this.ikm.nombreTrajets
      this.refacturable = this.ikm.refacturable
      this.mission = this.missions.find(m => m.id === (this.ikm.mission?.id?? this.ikm.mission))
      this.updateDistance()
      await this.loadVehicleGrayCard()
    }
  },
  methods: {
    async downloadFile () {
      const file = await this.$store.dispatch('file/downloadFile', {id: this.vehicle.carteGrise })
      const blob = new Blob([new Uint8Array(file.data.data)], { type: file.mimetype })
      const link = document.createElement('a')
      link.download = file.filename
      const url = URL.createObjectURL(blob)
      link.href = url
      link.click()
      URL.revokeObjectURL(url)
    },
    async close (value) {
      this.dialogVehicle = false
      this.dialogTrajet = false
      await this.loadData()
      if (value) {
        if (value.jsonArrivee) {
          // this.trajet = this.trajets.find(t => t.id === value.id) // todo: bug ??
        } else {
          this.vehicle = this.vehicles.find(v => v.id === value.id)
        }
      }
    },
    async loadVehicleGrayCard () {
      if (this.me.role.name !== this.constants.ROLES.ADMIN) return
      const result = await this.$store.dispatch('file/downloadFile', {id: this.vehicle.carteGrise})
      getBase64FromDownloadedFile(result, (result) => {
        this.grayCardDialogImgSrc = result
        this.grayCardDialog = true
      })
    },
    // resetVehicleGrayCard () {
    //   this.grayCardDialogImgSrc = null
    //   this.grayCardDialog = false
    // },
    getBareme (vehicle) {
      switch (vehicle.category) {
        case 'Voiture thermique':
          switch (parseInt(vehicle.horsepower)) {
            case 1:
            case 2:
            case 3:
              return this.getCoeffsFromSettings('cars', 0)
            case 4:
              return this.getCoeffsFromSettings('cars', 1)
            case 5:
              return this.getCoeffsFromSettings('cars', 2)
            case 6:
              return this.getCoeffsFromSettings('cars', 3)
            default:
              return this.getCoeffsFromSettings('cars', 4)
          }
        case 'Voiture électrique':
          // eslint-disable-next-line no-case-declarations
          const majoration = 1 + this.settings.NDF.baremesIK.majorationElectrique
          // eslint-disable-next-line no-case-declarations
          let result
          switch (parseInt(vehicle.horsepower)) { // *1.settings
            case 1:
            case 2:
            case 3:
              result = this.getCoeffsFromSettings('cars', 0)
              Object.keys(result).forEach(key => { result[key] *= majoration })
              return result
            case 4:
              result = this.getCoeffsFromSettings('cars', 1)
              Object.keys(result).forEach(key => { result[key] *= majoration })
              return result
            case 5:
              result = this.getCoeffsFromSettings('cars', 2)
              Object.keys(result).forEach(key => { result[key] *= majoration })
              return result
            case 6:
              result = this.getCoeffsFromSettings('cars', 3)
              Object.keys(result).forEach(key => { result[key] *= majoration })
              return result
            default:
              result = this.getCoeffsFromSettings('cars', 4)
              Object.keys(result).forEach(key => { result[key] *= majoration })
              return result
          }
        case 'Moto':
          switch (parseInt(vehicle.horsepower)) {
            case 1:
            case 2:
              return this.getCoeffsFromSettings('motorbikes', 0)
            case 3:
            case 4:
            case 5:
              return this.getCoeffsFromSettings('motorbikes', 1)
            default:
              return this.getCoeffsFromSettings('motorbikes', 2)
          }
        case 'Cyclomoteur':
          // eslint-disable-next-line no-case-declarations
          let defaultBareme = this.settings.NDF.baremesIK.motorcycles.default
          return defaultBareme === 1 ? { value: this.settings.NDF.baremesIK.motorcycles.coefficients[0].firstCol, c: 0 } : defaultBareme === 2 ? { value: this.settings.NDF.baremesIK.motorcycles.coefficients[0].secondCol, c: this.settings.NDF.baremesIK.motorcycles.coefficients[0].thirdCol } : { value: this.settings.NDF.baremesIK.motorcycles.coefficients[0].fourthCol, c: 0 }
      }
    },
    getDefaultBareme (vehicle) {
      switch (vehicle.category) {
        case 'Voiture électrique':
        case 'Voiture thermique':
          return this.settings.NDF.baremesIK['cars'].default
        case 'Moto':
          return this.settings.NDF.baremesIK['motorbikes'].default
        case 'Cyclomoteur':
          return this.settings.NDF.baremesIK['motorcycles'].default
      }
    },
    getCoeffsFromSettings (vehicle, i) {
      const defaultBareme = this.settings.NDF.baremesIK[vehicle].default
      switch (defaultBareme) {
        case 1:
          return { value: this.settings.NDF.baremesIK[vehicle].coefficients[i].untilFiveThousandCoefficientN, c: 0 }
        case 2:
          return { value: this.settings.NDF.baremesIK[vehicle].coefficients[i].fiveThousandUntilTwentyThousandCoefficientN, c: this.settings.NDF.baremesIK[vehicle].coefficients[0].fiveThousandUntilTwentyThousandCoefficientNPlusOne }
        default:
          return { value: this.settings.NDF.baremesIK[vehicle].coefficients[i].moreThanTwentyThousandCoefficientN, c: 0 }
      }
    },
    async loadData () {
      this.$store.state.misc.loading = true
      this.vehicles = await this.$store.dispatch('vehicle/getAllVehiclesByIndependent', this.independent.id)
      this.trajets = await this.$store.dispatch('trajet/getAllTrajetsByIndependent', this.independent.id)
      this.missions = await this.$store.dispatch('mission/getMissionsByIndependent', this.independent)
      this.$store.state.misc.loading = false
    },
    updateDistance (event) {
      if (event) this.distance = event.value
      this.distanceTotale = (this.nombreTrajets * this.distance).toFixed(2)
    },
    updateNombreTrajets (event) {
      if (event) this.nombreTrajets = event.value
      this.distanceTotale = (this.nombreTrajets * this.distance).toFixed(2)
    },
    async getDistance () {
      if (this.lieuDepart && this.lieuArrivee) {
        const geopos = {start: this.lieuDepart.geometry.coordinates, end: this.lieuArrivee.geometry.coordinates}
        this.$store.state.misc.loading = true
        const result = await this.$store.dispatch('apigouv/distanceAdresses', geopos)
        this.$store.state.misc.loading = false
        this.distance = result.data.distance.toFixed(2)
        this.calculatedDistance = result.data.distance.toFixed(2)
        this.updateDistance()
      }
    },
    async getAutocomplete (e) {
      this.searchResultDescription = 'Recherche en cours...'
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(async () => {
        const response = await this.$store.dispatch('apigouv/autocompleteAdresse', e.value)
        this.arriveeLoading = false
        this.departLoading = false
        this.suggestions = response
        this.searchResultDescription = response.length === 0 ? 'Aucune adresse n\'a été trouvée' : ''
      }, 1000)
    },
    async saveAndAssignIkm () {
      await this.saveIkm(false)
      await this.$store.dispatch('ikm/assignIkm', this.ikmBD)
      this.$store.state.misc.loading = false
      this.$toast.add({severity:'success', summary:'Succès', detail:'IKM assignée', life: 3000});
      this.$emit('reload')
      this.$emit('close')
    },
    async removeIkm () {
      this.$confirm.require({
        header: 'Supprimer IKM',
        message: 'Êtes-vous sûr de vouloir supprimer cette IKM ?',
        acceptLabel: 'Oui',
        rejectLabel: 'Non',
        accept: async () => {
          this.$store.state.misc.loading = true
          await this.$store.dispatch('ikm/deleteIkm', this.ikm)
          this.$store.state.misc.loading = false
          this.$toast.add({severity:'success', summary:'Succès', detail:'IKM supprimée', life: 3000});
          this.$emit('reload')
          this.$emit('close')
          this.$confirm.close()
        },
        reject: () => {

        }
      })
    },
    async saveIkm (quitAfter = true) {
      this.$store.state.misc.loading = true

      let dateIkm = new Date()
      if (this.dates?.length) {
        dateIkm = new Date(this.dates[0])
      }
      const ikm = {
        dates: this.dates,
        date: dateIkm,
        vehicle: this.vehicle,
        depart: this.lieuDepart,
        arrivee: this.lieuArrivee,
        distanceTrajet: this.distance,
        calculatedDistance: this.calculatedDistance,
        price: Math.round(this.prixIKMComputed.toFixed(2) * 100),
        independent: this.independent.id,
        nombreTrajets: this.nombreTrajets,
        refacturable: this.refacturable,
        mission: this.mission?.id
      }

      if (quitAfter) ikm.motifRefus = ''

      if (this.ikm?.id) {
        ikm['id'] = this.ikm.id
        await this.$store.dispatch('ikm/updateIkm', ikm)
      } else {
        await this.$store.dispatch('ikm/createIkm', ikm)
      }

      if (quitAfter) {
        this.$store.state.misc.loading = false
        this.$toast.add({severity:'success', summary:'Succès', detail:'IKM sauvegardée', life: 3000});
        this.$emit('reload')
        this.$emit('close')
      }
    },
    formatEuro (value) {
      return getEuroFormat(value)
    },
  }
}
</script>

<style>
.dialog-content-loading {
  background-color: transparent !important;
}
</style>

<style lang="scss" scoped>
::v-deep(.p-dialog) {
  box-shadow: none !important;
}
</style>
